html, body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
  //overflow: hidden;
}

$ibblauw: #1779ba;
$donkerblauw: #0f507a;

.ibblauw {
	background-color: $ibblauw;
}

.fullheight {
		height: 100%;
	}

.detail {
	.topband {
		background-color: $primary-color;

		img {
			padding: 8px;
		}

		.title {
			font-family: 'Amatic SC', cursive;
				    font-weight: bold;
				    font-size: 35px;
				    color: white;
		}
		
	}
	
	.navigation {
		width: 250px;
		background-color: $primary-color;
		font-family: 'Montserrat', sans-serif;
		font-weight: bold;
		.boven {
			padding: 1em 1.2em;
		}
		.midden {
			//width: 100%;
			.mymenu {
				width: 100%;
				// padding: 0 0 0 1.7rem;

				
				.menuitem {
					height: 44px;
					padding: 0rem .5rem;
					margin: 0 .8rem;
					border-top: 1px solid white;
					background-color: $donkerblauw;

					.icon {
						height: 32px;
						max-width: 35px;
						&.watcher {
							background: url(../img/icons/mediawatcher.svg) no-repeat center center;
							background-size: contain;
						}
						&.pirate {
							background: url(../img/icons/pirate.svg) no-repeat center center;
							background-size: contain;
						}
						&.persexpert {
							background: url(../img/icons/persexpert.svg) no-repeat center center;
							background-size: contain;
						}
						&.fascinerend {
							background: url(../img/icons/fascinerend.svg) no-repeat center center;
							background-size: contain;
						}
						&.goednieuws {
							background: url(../img/icons/goednieuws.svg) no-repeat center center;
							background-size: contain;
						}
						&.itsme {
							background: url(../img/icons/me.svg) no-repeat center center;
							background-size: contain;
						}
						&.contact {
							background: url(../img/icons/contact.svg) no-repeat center center;
							background-size: contain;
						}
					}
					.inwords {
						margin: 0rem 0rem 0rem .75rem;
						color: white;
						font-family: 'Amatic SC', cursive;
					    font-weight: 900;
					    font-size: 24px;
					    letter-spacing: -0.05rem;
					}
					&.last {
						border-bottom: 1px solid white;
					}
					&:hover {
						.inwords {
							color: $ibblauw;
						}
						.icon {
							&.watcher {
								background: url(../img/icons/mediawatcher-blauw.svg) no-repeat center center;
								background-size: contain;
							}
							&.pirate {
								background: url(../img/icons/pirate-blauw.svg) no-repeat center center;
								background-size: contain;
							}
							&.persexpert {
								background: url(../img/icons/persexpert-blauw.svg) no-repeat center center;
								background-size: contain;
							}
							&.fascinerend {
								background: url(../img/icons/fascinerend-blauw.svg) no-repeat center center;
								background-size: contain;
							}
							&.goednieuws {
								background: url(../img/icons/goednieuws-blauw.svg) no-repeat center center;
								background-size: contain;
							}
							&.itsme {
								background: url(../img/icons/me-blauw.svg) no-repeat center center;
								background-size: contain;
							}
							&.contact {
								background: url(../img/icons/contact-blauw.svg) no-repeat center center;
								background-size: contain;
							}
						}
						
				
					}
					&.actief, &.active {
						background-color: white;
						border-top: 1px solid $ibblauw;
						.icon {
							&.watcher {
								background: url(../img/icons/mediawatcher-blauw.svg) no-repeat center center;
								background-size: contain;
							}
							&.pirate {
								background: url(../img/icons/pirate-blauw.svg) no-repeat center center;
								background-size: contain;
							}
							&.persexpert {
								background: url(../img/icons/persexpert-blauw.svg) no-repeat center center;
								background-size: contain;
							}
							&.fascinerend {
								background: url(../img/icons/fascinerend-blauw.svg) no-repeat center center;
								background-size: contain;
							}
							&.goednieuws {
								background: url(../img/icons/goednieuws-blauw.svg) no-repeat center center;
								background-size: contain;
							}
							&.itsme {
								background: url(../img/icons/me-blauw.svg) no-repeat center center;
								background-size: contain;
							}
							&.contact {
								background: url(../img/icons/contact-blauw.svg) no-repeat center center;
								background-size: contain;
							}
						}
						.inwords {
							color: $ibblauw;
						}
						
					}

				}

			}
			
		}
		.beneden {
			height: 180px;
			color: white;
			a {
				i {
					color: $donkerblauw;
					background-color: white;
					font-size: 1.6em;
					border-left: 2px solid white;
					border-right: 2px solid white;
					&:hover {
						background-color: $ibblauw;
						border-left: 2px solid $ibblauw;
						border-right: 2px solid $ibblauw;
					}
				}
			}
			
		}
	}
	.mainarea {
		.boven {
			padding-top: 1em;
			.rubriek {
				font-variant: all-small-caps;
				font-size: larger;
				color: $primary-color;
				i {
					margin: 0 .3rem;
					font-size: .9rem;
				}
			}
		}
		.midden {

			overflow-x: hidden;
			h1 {
				font-family: 'Amatic SC', cursive;
				font-size: calc(3.2em + 3.2vw);
				font-variant: all-small-caps;
				font-weight: bold;
				//line-height: 1;
				line-height: calc(.7em + 0.5vw);
				color: $primary-color;
				margin-top: .2em;
			}
			h2 {
				font-size: calc(1em + 1vw);
				font-weight: bold;
				line-height: calc(1em + 0.4vw);
				color: $primary-color;
			}
			h3.hoezo, h3.meer {
				font-family: 'Amatic SC', cursive;
				font-size: calc(2em + 2vw);
				font-variant: all-small-caps;
				font-weight: bold;
				color: $primary-color;
				margin-top: 3rem;
			}
			.tags {
				font-size: .85em;
				margin-top: 1.2em;
			}

			.quote {

				position: relative;
				display: block;
				margin: 1.5em 0 2.5em;
				padding: 1em 1em;
				border-top: 3px solid $donkerblauw;
				border-bottom: 3px solid  $donkerblauw;
				color: $primary-color;
				font-size: x-large;

				.socialmedia {
					position: absolute;
					bottom: -2rem;
					left: 50%;
					transform: translate(-50%, -50%);
  					height: 2.2rem;
  					background-color: white;
  					padding: 0 1rem;
  					button.button {
  						background-color: $donkerblauw;
  						&:hover {
  							color: $primary-color;
  						}
  					}
				}

			}

			.quotestrook {
				margin: 1.5em 0 2.5em;
				padding: 1em 1em;
				color: $primary-color;
				font-size: x-large;

				img.fascinerendemens {
					max-height: 475px;
				}
			}

			.button {
				background-color: $donkerblauw;
				&:hover {
					color: $primary-color;
				}
			}

			.reclameblokje {
				margin-top: 1rem;
				margin-bottom: 3em;
				//background-color: #BA7717;
				//color: white;
				p {
					padding: 0 10px;
				}
				img {
					padding: 10px;
				}
			}
		}
		.overzicht {
			overflow-x: hidden;
			padding-bottom: 10em;
			.rubriekintro {
				padding: 1em 1em;
				p {
					font-size: large;
					line-height: 1.5;
					color: $ibblauw;
				}
			}
			.benieuwd {
				a.mylink {
					display: inline-block;
					color: $donkerblauw;
					overflow: hidden;

					
					.tekst {
						margin-left: -135px;
						font-weight: 900;
						transition: .2s ease-in-out;
					}
					.icon {
						padding-left: 10px;
					    vertical-align: middle;
					    font-size: 30px;
					}
					&:hover {
						.tekst {
							margin-left: 0px;
						}
					}
				}
			}
			
			h2 {
				font-family: 'Amatic SC', cursive;
				font-weight: bold;
				font-size: calc(1.3em + 1.8vw); 
				line-height: 1;
				margin-top: 1em;
			}
			p {
				font-size: calc(.7rem + .3vw);
				line-height: 1.1;
				margin: .3em;

			}
		}
		.huurling,.contact {
			overflow-x: hidden;
			padding-bottom: 10em;
			ul.accordion {
				margin-top: 1em;
				.accordion-title {
					font-family: Amatic SC;
				    font-size: calc(.9em + 1.2vw);
				    font-weight: 900;
				    &::before {
				    	font-family: Amatic SC;
				    	font-size: calc(.9em + 1vw);
				    	margin-top: 0;
				    	top: 0;
				    	padding-top: 1rem;
				    }
				}
			}
		}
		.beneden {
			.my-hover-reveal-wrapper {

				&:hover {
					.mailinglist {
						height: 90vh;
					}
				}
				.mailinglist {
					height: 3em;
					display: flex;
					background-color: $ibblauw;
					transition: .5s;
					color: white;
				}
			}

		}
	}
	.bband {
		background-color: $primary-color;
		padding: .5em;

		a {
			display:flex;
			padding: 0rem .5rem;
			img.icon {
				width: 40px;
				max-height: 45px;
				vertical-align: middle;
				margin: 0 auto;
			}
		}
	}
	.bband2 {
		padding: .5em;
		a.icon {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: $donkerblauw;
			margin-right: .5em;
			padding: .2em;
			.watcher {
				display: block;
				height: 40px;
				width: 40px;
				background: url(../img/icons/mediawatcher.svg) no-repeat center center;
				background-size: contain;
				
			}
			.pirate {
				display: block;
				height: 40px;
				width: 40px;
				background: url(../img/icons/pirate.svg) no-repeat center center;
				background-size: contain;
				
			}
			.persexpert {
				display: block;
				height: 40px;
				width: 40px;
				background: url(../img/icons/persexpert.svg) no-repeat center center;
				background-size: contain;
				
			}
			.fascinerend {
				display: block;
				height: 40px;
				width: 40px;
				background: url(../img/icons/fascinerend.svg) no-repeat center center;
				background-size: contain;
				
			}
			.goednieuws {
				display: block;
				height: 40px;
				width: 40px;
				background: url(../img/icons/goednieuws.svg) no-repeat center center;
				background-size: contain;
				
			}
			.itsme {
				display: block;
				height: 40px;
				width: 40px;
				background: url(../img/icons/me.svg) no-repeat center center;
				background-size: contain;
				
			}
			.contact {
				display: block;
				height: 40px;
				width: 40px;
				background: url(../img/icons/contact.svg) no-repeat center center;
				background-size: contain;
				
			}
			&.active {
				background-color: white;
				border: 1px solid $ibblauw;

				.watcher {
					background: url(../img/icons/mediawatcher-blauw.svg) no-repeat center center;
					background-size: contain;
					
				}
				.pirate {
					background: url(../img/icons/pirate-blauw.svg) no-repeat center center;
					background-size: contain;
					
				}
				.persexpert {
					
					background: url(../img/icons/persexpert-blauw.svg) no-repeat center center;
					background-size: contain;
					
				}
				.fascinerend {
					
					background: url(../img/icons/fascinerend-blauw.svg) no-repeat center center;
					background-size: contain;
					
				}
				.goednieuws {
					
					background: url(../img/icons/goednieuws-blauw.svg) no-repeat center center;
					background-size: contain;
					
				}
				.itsme {
					
					background: url(../img/icons/me-blauw.svg) no-repeat center center;
					background-size: contain;
					
				}
				.contact {
					
					background: url(../img/icons/contact-blauw.svg) no-repeat center center;
					background-size: contain;
					
				}

			}
		}		
		a {
			display:flex;
			padding: 0rem .5rem;
			img.icon {
				width: 40px;
				max-height: 45px;
				vertical-align: middle;
				margin: 0 auto;
			}
		}
	}
}



.start {
	.wimblauw {
		background-color: $primary-color;
		color:white;
		h1 {
			font-family: 'Amatic SC', cursive;
			font-weight: bold;
			font-size: 1.9em;
			padding-top: .2em;
			line-height: 1;
		}
		p.mission {
			padding-top: .7rem;
			margin-bottom: 0;
			//font-weight: bold;
			line-height: 1.4;
		}
		p.ondertekening {
			font-size: smaller;
		}
		@media screen and (min-width: 40em) {
			
			background-image: url('../img/iedereenbenieuwd-rechts-wit.svg');
			background-size: calc(10px + 6vw);
			background-repeat: no-repeat;
			background-position: center left;
		}
		@media screen and (max-width: 39.9375em) {
			background-image: url('../img/iedereenbenieuwd-boven-wit.svg');
			background-size: calc(120px + 3vw);
			background-repeat: no-repeat;
			background-position: bottom center;
		}
		.my-hover-reveal-wrapper {

			&:hover {
				.pretitel {
				  display: none;
				}
				.titel {
					display: flex;
				}
			}
			.pretitel {
				//display: none;
				img {
					width: 50px;
				} 
			}
			.titel {
				display: none;
				//display: flex;
				padding: 0 5em;
				@media screen and (max-width: 39.9375em) {
					display: flex;
				}
				z-index: 1;
				//margin: 0 8em;
				.rubriek {
					background-color: $donkerblauw;
					width: fit-content;
					display: inline-block;
					margin-bottom: 4px;
					a {
						.naartoe {
							height: 28px;
							display: flex;
							align-items: center;
							justify-content: center;
							.icon {
								height: 100%;
								width: 30px;
								margin: 0 5px;
								img {
									margin: 0 auto;
									padding: 1px;
									height: 100%;
								}
							}
							.wimnaam, .wimbijnaam {
								width: 120px;
								color: white;
								font-variant: all-small-caps;
								font-size: .9em;
								font-weight: bold;
								line-height: .75;
							}
						}
						&:hover {
							.naartoe{
								background-color: rgba(#fff, .1) ;
								transition: .2s;
							}
						}
					}
				}
				
				
					
				
				.benieuwd {
					a.mylink {
						display: inline-block;
						color: $donkerblauw;
						overflow: hidden;

						
						.tekst {
							margin-left: -135px;
							font-weight: 900;
							transition: .2s ease-in-out;
						}
						.icon {
							padding-left: 10px;
						    vertical-align: middle;
						    font-size: 30px;
						}
						&:hover {
							.tekst {
								margin-left: 0px;
							}
						}
					}
				}
				
				h2 {
					font-family: 'Amatic SC', cursive;
					font-weight: bold;
					font-size: calc(1.3em + 1.8vw); 
					line-height: 1;
				}
				p {
					font-size: calc(.7rem + .3vw);
					line-height: 1.1;
					margin-bottom:0;
				}
				p.mission {
					padding-top: .7rem;
					font-weight: bold;
					font-size: calc(1em + .5vw); 
					line-height: 1.4;
				}
				p.ondertekening {
					font-size: calc(.9em + .3vw); 
				}
					
				
			}
		}
	}
	.wimwit {
		background-color: white;
		color: $primary-color;
		@media screen and (max-width: 39.9375em) {
			background-image: url('../img/iedereenbenieuwd-beneden-blauw.svg');
			background-size: calc(120px + 3vw);
			background-repeat: no-repeat;
			background-position: top center;
		}

		@media screen and (min-width: 40em) {
			background-image: url('../img/iedereenbenieuwd-links-blauw.svg');
			background-size: calc(10px + 6vw);
			background-repeat: no-repeat;
			background-position: center right;
		}

		.mymenu {
			width: 100%;
			padding-top: 3em;
			a {

				.menuitem {
					padding: 0 0 ;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.icon {
						width: 45px;
						display: flex;
						justify-content: center;
						img {
							max-height: 25px;
						}
					}
					.inwords {
						margin: 0 0rem 0 0.3rem;
						font-family: 'Amatic SC', cursive;
					    font-weight: bold;
					    //font-size: 20px;
					    font-size: calc(1em + 1vw);
					}
					.arrowsright {
						width: 45px;
						display: flex;
						justify-content: center;
					}
					
				}
				&:hover {
					.menuitem {
						.arrowsright {
							justify-content: flex-end;
						}
					}
				}

			}

		}

		.my-hover-reveal-wrapper {

			&:hover {
				.pretitel {
				  display: none;
				}
				.titel {
					display: flex;
				}
			}
			.pretitel {
				//display: none;
				i {
					font-size: 2em;
				}
			}
			.titel {
				display: none;
				//display: flex;
				@media screen and (max-width: 39.9375em) {
					display: flex;
				}
				z-index: 1;
				font-family: 'Montserrat', sans-serif;
				.boven {
					h1 {
						font-family: 'Amatic SC', cursive;
						font-weight: bold;
						font-size: calc(2.2em + 2vw); 
						line-height: 1;
						padding: 0 .5em;
						//color: #0F507A;
					}
					p.mission {
						padding-top: .7rem;
						margin-bottom: 0;
						//font-weight: bold;
						font-size: calc(1em + .5vw); 
						line-height: 1.4;
					}
					p.ondertekening {
						font-size: calc(.6em + .5vw); 
						font-weight: normal;
						padding-top: 1em;
						//color: #0F507A;
					}
					
				}
				.onder {
					padding: 0 4.5em;
					font-weight: bold;
					font-size: calc(.8em + .4vw);
					@media screen and (max-width: 39.9375em) {
						font-size: calc(1.2em + .4vw);
					}
					.mymenu {
						width: auto;
						padding-top: 3em;
						margin: 0 auto;
						a .menuitem {
							//padding: .3rem 0 ;
							display: flex;
							justify-content: space-between;
							align-items: center;
							.icon {
								width: 45px;
								display: flex;
								justify-content: center;
								img {
									max-height: calc(15px + 1vh);
								}
							}
							.inwords {
								margin: 0 0rem 0 0.3rem;
								font-family: 'Amatic SC', cursive;
							    font-weight: bold;
							    font-size: calc(.55em + 1vw);
							}
							.arrowsright {
								width: 45px;
								display: flex;
								justify-content: center;
								i.fa-angle-double-right {
									font-size: calc(.2em + 1vw);
								}
							}
					
							@media screen and (min-width: 64em) {
								
								.icon {
									width: 35px;
									img {
										max-height: 40px;
									}
								}
								.inwords {
								    font-size: 1.3em;
								}
							}
							&:hover {
								.arrowsright {
									justify-content: flex-end;
								}

							}

						}

					}

				}
			}
		}

	}
}

// Voor de formulieren 

li.accordion-item a.accordion-title{
	background-color: #e6e6e6;
	h2 {
		margin-bottom: 0;
	}
}
textarea[name="jouwreactie"] {
	height: 10em;
}

label sup {
	cursor: pointer;
	color: $ibblauw;
}

.donotshow {
	display: none;
}

//

// TEST TEST IMAGE 
img.responsive {
	width: 100%;
	height: auto;
}

.fade-in {
  animation: fadeIn ease .7s;
  -webkit-animation: fadeIn ease .7s;
  -moz-animation: fadeIn ease .7s;
  -o-animation: fadeIn ease .7s;
  -ms-animation: fadeIn ease .7s;
}


@keyframes fadeIn{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.balk {
	height: 7em;

	div {
		padding: .2em .5em;
		img {
			height: 6.5em;
			contain: content;
		}
		&.auto {
			color: white;
			font-weight: 900;
		}
	}
}
.landingtitel {
	margin: 1em 0;

	@media screen and (max-width: 39.9375em) {
		img {
			margin: 1em 0;
		}
	}
}
.ctastrook {
	margin-top: -5em;
	@media screen and (max-width: 39.9375em) {
		margin-top: .5em;
	}
	.naamklein {
		color: $ibblauw;
		font-size: calc(1em + 1vw);
		font-weight: bold;
		margin-bottom: 1.5em;
		}
	.introcta {
		font-size: calc(.5em + 1.3vw);
		font-weight: bold;
		@media screen and (max-width: 39.9375em) {
			font-size: calc(.8em + 1vw);
		}
	}
	.textcta {
		.button {
			background-color: #BA7717;
			padding: .2em .4em .2em .4em;
			color: white;
			font-size: calc(.5em + 1.9vw);
			font-weight: bold;
			@media screen and (max-width: 39.9375em) {
				font-size: calc(.8em + 1.9vw);
			}
			&:hover {
				background-color: #6D4000;
				color: #BA7717; 
			}
		}
		@media screen and (max-width: 39.9375em) {
			margin-bottom: 2em;
		}
	}

}
.reststrook {
	margin-bottom: 10em;
	img {
		margin-top: 1em;
	}
	.mijnnaam {
		padding: 1em 0 .2em;
		margin: 0 auto;
		color: $ibblauw;
		font-size: calc(.5em + 1.9vw);
		font-weight: bold;
	}
	.ctastrook {
		margin-top: 2em;
	}
}


	img.testing {
		width: 100%;
		height: auto;
	}
